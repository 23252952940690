import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// Create a context to hold the state and functions
const StudentDetailsContext = createContext();

// Create a custom hook to consume the context
const useStudentDetails = () => {
  return useContext(StudentDetailsContext);
};

// Create a provider component to wrap the app with the context
export const StudentDetailsProvider = ({ children }) => {
  // State variables for various data

  const [showDetails, setShowDetails] = useState(false);
  const [session, setSession] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [studentId, setStudentId] = useState('');
  const [sName, setSName] = useState('');
  const [admissionId, setAdmissionId] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(undefined);
  const [showTable, setShowTable] = useState(undefined);
  const [studentData, setStudentData] = useState([]);
  const [SchoolCode, setSchoolCode] = useState("");

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (queryParams.has("School_Code")) {
      const parameterValue = queryParams.get('School_Code');
      setSchoolCode(parameterValue);
    }
  },[SchoolCode]);


  // Fetch classes data when the component mounts
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(
          `https://legacy.swiftcampus.com/UnniversalAppAPI.php?School_Code=${SchoolCode}&parameter=getAllclass&school_id=1&Client_Type=APP`,
        );
        setClasses(response.data.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, [SchoolCode]);

  // Fetch sections data when the selected class changes
  useEffect(() => {
    const fetchSections = async () => {
      if (selectedClass) {
        try {
          const response = await axios.get(
            `https://legacy.swiftcampus.com/UnniversalAppAPI.php?School_Code=${SchoolCode}&parameter=getAllsectionbyClass&school_id=1&class_id=${selectedClass}&Client_Type=APP/`,
          );
          setSections(response.data.sections);
        } catch (error) {
          console.error('Error fetching sections:', error);
        }
      }
    };

    fetchSections();
  }, [selectedClass]);

  // Function to handle search based on the selected parameters
  const handleSearch = async () => {
    setLoader(true);
    const url = `https://legacy.swiftcampus.com/NewApis.php?School_Code=${SchoolCode}&get_stud_details_by_sec=1&school_session=${session}&search_type=${searchBy}&stud_data=${selectedClass}&sec_id=${selectedSection}&stud_id=${studentId}&addmission_no=${admissionId}&stud_name=${sName}`;
    try {
      const response = await axios.get(url);
      const responseData = response.data.data || [];
      setTableData(responseData);
      setShowTable(true);
      setLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowTable(false);
      setLoader(false);
    }
  };

  //function to fetch student details by student id
  const studentDataSearch = async (st_id) => {
    const url = `https://legacy.swiftcampus.com/Student_Module_APIController.php?School_Code=${SchoolCode}&type=get_student_details&student_id=${st_id}&session=${session}`;
    try {
      const response = await axios.get(url);
      const responseData = response.data.data || [];
      setStudentData(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  // Context value containing state variables and functions
  const contextValue = {
    showDetails,
    setShowDetails,
    session,
    setSession,
    searchBy,
    setSearchBy,
    studentId,
    setStudentId,
    sName,
    setSName,
    admissionId,
    setAdmissionId,
    classes,
    setClasses,
    selectedClass,
    setSelectedClass,
    sections,
    setSections,
    selectedSection,
    setSelectedSection,
    tableData,
    setTableData,
    loader,
    setLoader,
    showTable,
    setShowTable,
    handleSearch,
    studentData,
    setStudentData,
    studentDataSearch,
  };

  // Provide the context value to the children components
  return (
    <StudentDetailsContext.Provider value={contextValue}>
      {children}
    </StudentDetailsContext.Provider>
  );
};

// Prop types for the StudentDetailsProvider component
StudentDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useStudentDetails;
